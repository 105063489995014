// eslint-disable-next-line no-unused-vars
import config from "@config";
import "@styles/login";
// import 'airbnb-browser-shims'; // Uncomment if needed

// Your code goes here ...
jQuery(document).ready(function () {
  jQuery("#login h1 a").attr("href", "/");
  jQuery("#login h1 a").attr("target", "_blank");
});
